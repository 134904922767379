<template>
  <div class="static-cookies">
    <!-- Remove modal -->
    <CModal
      :title="modal.title"
      color="danger"
      :show.sync="modal.show"
      :centered="true"
    >
      {{ modal.message }}
      <template #footer>
        <button type="button" class="btn btn-secondary" @click="closeModal">
          Cancelar
        </button>
        <button type="button" class="btn btn-danger" @click="remove">
          Remover
        </button>
      </template>
    </CModal>

    <CForm @submit.prevent="send">
      <CRow>
        <CCol col="12">
          <CCard class="border-0">
            <!-- Title -->
            <CCardHeader>
              <CRow class="align-items-center">
                <CCol sm="12" md="6">
                  <h4 class="mb-0 card-title">Cookies</h4>
                </CCol>
                <CCol sm="12" md="6" class="card-header-actions">
                  <CButton
                    v-if="items.length > 0"
                    color="danger"
                    class="float-right ml-2"
                    @click="removeModal(null)"
                  >
                    <CIcon name="cil-trash" class="mr-1 my-0" />
                    <span style="padding-top: 2px">Remover cookies</span>
                  </CButton>
                  <CButton color="primary" class="float-right" @click="addItem">
                    <CIcon name="cil-library-add" class="mr-2" />
                    Adicionar cookie
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>

            <CCardBody>
              <CAlert color="info">
                Para exibir essas informações é necessário ativar o modo
                avançado dos cookies nas
                <a href="/settings" target="_blank" class="text-info"
                  ><b>Configurações</b></a
                >.
              </CAlert>
              <!-- Content -->
              <CRow v-if="items.length > 0">
                <CCol
                  v-for="(item, index) in items"
                  :key="item[index]"
                  sm="12"
                  md="6"
                  lg="4"
                >
                  <CCard class="card-static-cookies">
                    <CCardHeader>
                      <CRow class="align-items-center">
                        <CCol>
                          <b class="mb-0"> Detalhes do cookie </b>
                        </CCol>
                        <CCol>
                          <div class="card-header-actions">
                            <a
                              v-c-tooltip="'Remover'"
                              href="javascript:void(0)"
                              class="d-flex align-items-center text-danger"
                              placement="top"
                              @click="removeModal(index)"
                            >
                              <CIcon name="cil-trash"> </CIcon>
                            </a>
                          </div>
                        </CCol>
                      </CRow>
                    </CCardHeader>
                    <CCardBody>
                      <CRow>
                        <CCol lg="12">
                          <div class="form-group">
                            <label :for="`category-${index}`">Categoria</label>
                            <Multiselect
                              :id="`category-${index}`"
                              :value="
                                hasCategoriesOptions.find(
                                  (option) => option.value === item.category
                                )
                              "
                              :options="hasCategoriesOptions"
                              :required="true"
                              @select="
                                (selected) => (item.category = selected.value)
                              "
                            />
                          </div>
                        </CCol>
                        <CCol sm="12">
                          <CInput
                            v-model="item.title"
                            label="Título"
                            placeholder="Digite o título do cookie"
                            :description="`${item.title.length}/${titleLength} caracteres`"
                            :maxlength="titleLength"
                            :required="true"
                          />
                        </CCol>
                        <CCol sm="12">
                          <CInput
                            v-model="item.slug"
                            label="Identificador"
                            placeholder="Ex: _ga_"
                            :add-input-classes="`input-url-${index}`"
                            :required="true"
                          />
                        </CCol>
                        <CCol sm="12">
                          <CTextarea
                            v-model="item.description"
                            label="Descrição"
                            placeholder="Digite a descrição da cookie"
                            class="mb-0"
                            :description="`${item.description.length}/${descriptionLength} caracteres`"
                            :maxlength="descriptionLength"
                          />
                        </CCol>
                        <!--  -->
                        <CRow>
                          <CCol l lg="12">
                            <div class="mt-1 ml-3 d-flex align-items-center">
                              <label class="mb-0">
                                <b>
                                  Usúario poderá ativar ou inativar este cookie?
                                </b>
                              </label>
                              <CSwitch
                                type="checkbox"
                                color="success"
                                variant="3d"
                                class="mx-2"
                                v-bind="labelIcon"
                                :checked.sync="item.optional"
                              />
                            </div>
                          </CCol>
                        </CRow>
                        <!--  -->
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>

              <!-- No content -->
              <p v-else class="mb-0 text-muted">
                Não há cookies cadastradas,
                <a href="javascript:void(0)" class="text-info" @click="addItem"
                  >clique aqui</a
                >
                para adicionar um novo cookie.
              </p>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol class="mt-3 mb-5">
          <div class="d-flex justify-content-end align-items-center">
            <CButton color="success" type="submit" :disabled="processing">
              {{ processing ? 'Salvando...' : 'Salvar' }}
            </CButton>
          </div>
        </CCol>
      </CRow>
    </CForm>
  </div>
</template>

<script>
import Multiselect from '@/components/ui/Multiselect'
import CookiesService from '@/services/cookies.service'

export default {
  metaInfo: {
    title: 'Cookies',
    titleTemplate: ' %s - Manager - OW Interactive'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_cookies')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    Multiselect
  },

  data() {
    return {
      items: [],
      titleLength: 70,
      descriptionLength: 160,
      pageToRemove: null,
      modal: {
        show: false,
        title: '',
        message: ''
      },
      processing: false,
      hasCategoriesOptions: [
        {
          value: 1,
          label: 'Necessário'
        },
        {
          value: 2,
          label: 'Marketing'
        },
        {
          value: 3,
          label: 'Analytics'
        },
        {
          value: 4,
          label: 'Outros'
        }
      ],
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      }
    }
  },

  async created() {
    const allCookies = await CookiesService.listAll()
    const storedCookies = allCookies.map((cookie) => {
      return {
        category: cookie.category,
        slug: cookie.slug,
        description: cookie.description,
        title: cookie.title,
        optional: cookie.optional
      }
    })

    this.items = storedCookies
  },

  methods: {
    addItem() {
      this.items.unshift({
        category: '',
        slug: '',
        title: '',
        description: '',
        optional: true
      })

      setTimeout(() => {
        const firstInputUrl = document.querySelector('.input-url-0')
        firstInputUrl.focus()
      }, 500)
    },

    removeModal(page) {
      this.pageToRemove = page
      this.modal = {
        show: true,
        title:
          this.pageToRemove !== null
            ? 'Remover cookie'
            : 'Remover todas os cookies',
        message:
          this.pageToRemove !== null
            ? 'Ao confirmar essa ação o cookie não poderá ser recuperada, tem certeza que desejar continuar?'
            : 'Ao confirmar essa ação os cookies não poderão ser recuperadas, tem certeza que desejar continuar?'
      }
    },

    remove() {
      if (this.pageToRemove !== null) {
        this.items.splice(this.pageToRemove, 1)
        this.pageToRemove = null
        this.closeModal()
      } else {
        this.items = []
        this.closeModal()
      }
    },

    closeModal() {
      this.modal = {
        show: false,
        title: '',
        message: ''
      }
    },

    async send() {
      this.processing = true
      const response = await CookiesService.setCookies(this.items)

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: 'Cookies salvos com sucesso!'
        })
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Erro ao salvar o cookies, tente novamente mais tarde.'
        })
      }
      this.processing = false
    }
  }
}
</script>

<style lang="scss">
.card-static-cookies {
  textarea {
    height: 80px !important;
  }
}

@media only screen and (max-width: 768px) {
  .static-cookies {
    .card {
      .card-title {
        margin-bottom: 1em !important;
      }

      .card-header-actions {
        button {
          float: none !important;
          margin-left: 0px !important;
          margin-right: 0.5rem !important;

          &:last-child {
            margin-right: 0px !important;
          }
        }
      }
    }
  }
}
</style>
